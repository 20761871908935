import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

export default function Carsnew() {
  return (
    <Layout>

      <div class="container">
      <div class="row mx-lg-n5 mt-3">
            <h5>All New Car Makes</h5>
        </div>
        
        <div class="row mx-lg-n5 mt-3">
          <div class="col-md-2"><Link to="/carsnew?make=alfa">Alfa Romeo</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=alpine">Alpine</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=aston">Aston Martin</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=audi">Audi</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=bentley">Bentley</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=bmw">BMW</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=byd">BYD</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=citroen">Citroen</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=ds">DS</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=ferrari">Ferrari</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=fiat">Fiat</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=ford">Ford</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=golden">Golden Dragon</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=hino">Hino</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=honda">Honda</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=hyundai">Hyundai</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=infiniti">Infiniti</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=land">Land Rover</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=lexus">Lexus</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=lotus">Lotus</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=maserati">Maserati</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=maxus">Maxus</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=mazda">Mazda</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=mclaren">McLaren</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=mercedes">Mercedes-Benz</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=mg">MG</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=mini">Mini</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=mitsubishi">Mitsubishi</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=nissan">Nissan</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=opel">Opel</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=perodua">Perodua</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=peugeot">Peugeot</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=porsche">Porsche</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=renault">Renault</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=rolls">Rolls-Royce</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=seat">SEAT</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=skoda">Skoda</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=ssangyong">Ssangyong</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=subaru">Subaru</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=suzuki">Suzuki</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=tesla">Tesla</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=toyota">Toyota</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=volkswagen">Volkswagen</Link></div>
          <div class="col-md-2"><Link to="/carsnew?make=volvo">Volvo</Link></div>
        </div>

        <div class="row mx-lg-n5 mt-5">
            <h5>Latest Used Cars</h5>
        </div>

        <div class="row mx-lg-n5">
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_12.jpg" class="card-img-top" alt="20201015_carimage_12" />
                    <div class="card-body">
                    <h5 class="card-title">Skoda Octavia</h5>
                    <p class="card-text">With its compact dimensions, state-of-the-art driver-assistance systems, perfect for city traffic and long hauls alike.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_13.jpg" class="card-img-top" alt="20201015_carimage_13" />
                    <div class="card-body">
                    <h5 class="card-title">Audi A6</h5>
                    <p class="card-text">The Audi A6 is elegant and sporty in design with combination of cutting-edge technology and unmistakable sophistication.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_14.jpg" class="card-img-top" alt="20201015_carimage_14" />
                    <div class="card-body">
                    <h5 class="card-title">Audi Q5</h5>
                    <p class="card-text">Expressive appearance, powerful drive, and pioneering technology. Your options are just as diverse.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                </div>
              </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_12.jpg" class="card-img-top" alt="20201015_carimage_12" />
                    <div class="card-body">
                    <h5 class="card-title">Skoda Octavia</h5>
                    <p class="card-text">With its compact dimensions, state-of-the-art driver-assistance systems, perfect for city traffic and long hauls alike.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_13.jpg" class="card-img-top" alt="20201015_carimage_13" />
                    <div class="card-body">
                    <h5 class="card-title">Audi A6</h5>
                    <p class="card-text">The Audi A6 is elegant and sporty in design with combination of cutting-edge technology and unmistakable sophistication.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_14.jpg" class="card-img-top" alt="20201015_carimage_14" />
                    <div class="card-body">
                    <h5 class="card-title">Audi Q5</h5>
                    <p class="card-text">Expressive appearance, powerful drive, and pioneering technology. Your options are just as diverse.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                </div>
              </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_12.jpg" class="card-img-top" alt="20201015_carimage_12" />
                    <div class="card-body">
                    <h5 class="card-title">Skoda Octavia</h5>
                    <p class="card-text">With its compact dimensions, state-of-the-art driver-assistance systems, perfect for city traffic and long hauls alike.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_13.jpg" class="card-img-top" alt="20201015_carimage_13" />
                    <div class="card-body">
                    <h5 class="card-title">Audi A6</h5>
                    <p class="card-text">The Audi A6 is elegant and sporty in design with combination of cutting-edge technology and unmistakable sophistication.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_14.jpg" class="card-img-top" alt="20201015_carimage_14" />
                    <div class="card-body">
                    <h5 class="card-title">Audi Q5</h5>
                    <p class="card-text">Expressive appearance, powerful drive, and pioneering technology. Your options are just as diverse.</p>
                    <Link to="/carsnewprice" class="btn btn-danger btn-block">Available Prices</Link>
                </div>
              </div>
            </div>

        </div>

        <div class="row mx-lg-n5 mt-5">

        </div>

      </div>
    </Layout>
  )
}